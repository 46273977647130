import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`

const Index = () => {
    return (
        <>
            <ContainerTitle>
                <H2>Saltos</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">BARRIOS</a></li>
                        <li className="breadcrumb-item active" aria-current="page">SALTOS</li>
                    </ol>
                </nav>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <StaticImage src="../../../../images/logo-06.png" />
                        <H2 style={{ color: "#1E2E48" }} className="mt-5">Sobre Costa del Arroyo San Juan.</H2>
                        <H3>NAVEGABLE HASTA EL RIO PARANA EN LAS ALTURAS NORMALES DEL RIO</H3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Característica:</strong> Barrio abierto, próximamente. Recorrido por el Arroyo San Juan, este barrio cuenta con el privilegio de contar con el Salto del mismo nombre, cuyo paisaje es único y distinguido en medio de un ambiente puro y natural</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Ubicación:</strong> Lindante al Barrio “El Portal“ Superficie total común: 416.800 m2 (Cuatrocientos dieciseis mil ochocientos metros cuadrados)</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Bienes de Infraestructura:</strong> CBuen acceso, red de alumbrado público, espacios verdes y saltos</P>
                    </Col>
                </Row>
                <Row>
                    <Col lg={7}>
                        <StaticImage src="../../../../images/LosSaltos.png" className="mt-5" />
                    </Col>
                    <Col lg={5}>
                        <StaticImage src="../../../../images/LosSaltosTable.png" className="mt-5" />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Index;